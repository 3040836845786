export default {
  DEVICE_ID: `browser-${process.IS_TEST ? 'test-id' : self.crypto.randomUUID()}`, // deviceId gets embedded in JWT claim. For web this is arbitrary as long as we're only using the JWT to get a cookie session.
  MFA_EMAIL_TYPE: 'EMAIL',
  MFA_TOTP_TYPE: 'TOTP',
  MFA_FEATURE_FLAG_ENABLED: false, // TODO remove once 2fa testing is done
  MFA_AUTH_TOKEN: 'MFA_AUTH_TOKEN',
  MFA_LOGIN_TOKEN: 'MFA_LOGIN_TOKEN',
  GrantType: {
    PASSWORD: 'password',
    MFA_LOGIN: 'mfa_login',
    MFA_RECOVERY: 'mfa_recovery'
  },
  EnableEmailMFA: {
    name: 'Email authentication',
    enableNoticeText: 'Great job! We will email you a code the next time you log into TunnelBear.',
    img: require('../views/mfa-setup/mfa-enable-disable/images/email-auth-enable-bear.svg')
  },
  DisableEmailMFA: {
    name: 'Email authentication',
    disableNoticeText: 'You will no longer need an email authentication code when you log into TunnelBear.',
    img: require('../views/mfa-setup/mfa-enable-disable/images/email-auth-disable-bear.svg')
  },
  EnableTOTPMFA: {
    name: 'App authentication',
    enableNoticeText: 'Great job! You will be asked for a code the next time you log into TunnelBear.',
    img: require('../views/mfa-setup/mfa-enable-disable/images/app-auth-enable-bear.svg')
  },
  DisableTOTPMFA: {
    name: 'App authentication',
    disableNoticeText: 'You will no longer need an authentication code when you log into TunnelBear.',
    img: require('./../views/mfa-setup/mfa-enable-disable/images/app-auth-disable-bear.svg')
  }
}
